import { Tenant } from '../types/tenant';
import psiConfigJson from './tenant/psi/aws-exports.json';
import therapyConfigJson from './tenant/therapyed/aws-exports.json';

const configs = {
  [Tenant.psi]: psiConfigJson,
  [Tenant.therapyed]: therapyConfigJson,
};

export default configs;
