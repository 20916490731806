// passed in redundantly
// common-theme and branding-theme imported separately if
// nothing in common-theme depends on branding
import { Tenant } from '../types/tenant';
import {
  psi,
  therapyed,
} from './branding-theme';

const darkColors = {
  black: '#000000',
  background: '#121212',
  dark: '#212121',
  light: '#2D2D2D',
  surface: '#424242',
  opacity: 'rgba(0, 0, 0, 0.87)',
};

const error = {
  main: '#f44336',
};

const progress = {
  high: '#66bb68',
  medium: '#dda53a',
  low: '#d66565',
  none: '#ddd',
  noneBackground: '#888888', // old: '#888888'
  lowBackground: 'rgba(255,105,97, 0.3)', // old: '#ffb4b0'
  highBackground: 'rgba(0, 168, 106, 0.3)', // old: '#90d3b5'
};

const confidence = {
  high: '#66bb68',
  medium: '#dda53a',
  low: '#d66565',
};

const social = {
  facebook: '#3b5998',
  twitter: '#55acee',
  instagram: '#e95950',
  apple: '#1c1c1e',
  google: '#4285f4',
};

const textColors = (darkMode : boolean) => (
  darkMode ? {
    primary: 'rgba(255,255,255,0.87)',
    secondary: 'rgba(255,255,255,0.60)',
    disabled: 'rgba(255,255,255,0.38)',
  } : {
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.60)',
    disabled: 'rgba(0,0,0,0.38)',
  }
);

// Headline: 24
// Title: 20
// Subheading: 16
// Paragraph: 14
// Caption: 12
// StyledText: alpha, regular/medium/light/thin

// Use the above nomenclature instead?
const fontSizes = {
  h1: 48,
  h2: 40,
  h3: 34,
  h4: 32,
  h5: 24,
  h6: 20,
  subtitle1: 16,
  subtitle2: 14,
  body2: 14,
  button: 14,
  caption: 12,
  overline: 10,
};

const fontWeights = {
  extraBold: '800',
  bold: '700',
  semiBold: '600',
  medium: '500',
  regular: '400',
  light: '300',
  thin: '100',
};

const fontReusables = (darkMode : boolean) => ({
  overline: {
    fontSize: fontSizes.overline,
    fontWeight: fontWeights.semiBold,
    letterSpacing: 1.0,
    textTransform: 'uppercase',
    color: textColors(darkMode).secondary,
  },
  button: {
    fontSize: fontSizes.button,
    fontWeight: fontWeights.bold,
    letterSpacing: 1.25,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const branding : Record<Tenant, any> = {
  psi,
  therapyed,
};

export {
  branding,
  darkColors,
  error,
  progress,
  confidence,
  social,
  fontSizes,
  fontWeights,
  textColors,
  fontReusables,
};
