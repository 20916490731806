/* eslint-disable @typescript-eslint/no-explicit-any */
/* -----------------Globals--------------- */
import React, {
  useEffect,
  useState,
} from 'react';

/* -----------------Actions--------------- */
import { isAuthenticated } from '../functions/auth';

/* -----------------Utils--------------- */
import {
  Keys,
  store,
} from '../utils/auth';

/* -----------------Third parties--------------- */
import { atom } from 'jotai';

/* -----------------Props--------------- */
type AuthContextValue = {
  isAuthenticated: boolean,
  user: any,
  refreshAuth: any,
  viewerId: string | undefined,
  err: boolean,
  token: string | undefined,
  isEmailVerified: boolean,
  shouldLogout? : boolean,
  cognitoUserObjectAtom : any
 }
const cognitoUserObjectAtom = atom(undefined);

/* -----------------Constants--------------- */
const initialContextValue: AuthContextValue = {
  isAuthenticated: false,
  user: null,
  refreshAuth: undefined,
  viewerId: undefined,
  err: false,
  token: undefined,
  isEmailVerified: false,
  shouldLogout: false,
  cognitoUserObjectAtom,
};

/* -----------------Context--------------- */
const Context = React.createContext<AuthContextValue>(
  initialContextValue,
);

const AuthProvider = ({ children } : {children : React.ReactNode}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<any>(null);
  const [err, setError] = useState(false);
  const [token, setToken] = useState(undefined);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [shouldLogout, setShouldLogout] = useState<boolean | undefined>(false);
  const refreshAuth = async (logout? : boolean) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result : any = await isAuthenticated();
      if (result) {
        store(Keys.loggedIn, 'true');
        const {
          signInUserSession: {
            accessToken: {
              jwtToken,
            },
          },
          attributes: {
            email_verified: emailVerified,
            'custom:hasFbLogin': hasFbLogin,
            'custom:hasGoogleLogin': hasGoogleLogin,
            'custom:hasAppleLogin': hasAppleLogin,
          },
        } = result;
        setToken(jwtToken);
        const finalEmailVerified = emailVerified || (hasFbLogin || hasGoogleLogin || hasAppleLogin);
        setEmailVerified(finalEmailVerified);
      } else {
        store(Keys.loggedIn, 'false');
      }
      setUser(result);
      setShouldLogout(logout);
    } catch {
      setError(true);
      store(Keys.loggedIn, 'false');
      setUser(undefined);
    }
  };

  useEffect(() => { refreshAuth(); }, []);

  const contextValue = {
    isAuthenticated: !(!user),
    user,
    err,
    refreshAuth,
    token,
    isEmailVerified,
    shouldLogout,
    viewerId: user ? user.attributes['custom:logicalId'] : '',
    cognitoUserObjectAtom: initialContextValue.cognitoUserObjectAtom,
  };
  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
};

const useAuthContext = () => {
  const context = React.useContext(Context);
  return context;
};
export {
  Context as AuthContext,
  AuthProvider,
  useAuthContext,
};
