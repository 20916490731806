// @flow
/* -----------------Third parties--------------- */
import awsexportsConfigMap from './awsexports';
// eslint-disable-next-line import/extensions

/* -----------------Third parties--------------- */
import { Auth } from '@aws-amplify/auth';
import { Tenant } from '../types/tenant';

const graphqlHeader = async () => {
  try {
    const token = await (await Auth.currentSession()).getIdToken().getJwtToken();
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};

const setUpConfig = (tenant : Tenant, loggedIn?: boolean) => {
  const awsExports = awsexportsConfigMap[tenant];
  const UserPoolClients = JSON.parse(awsExports.UserPoolClients);

  const redirectSignIn = UserPoolClients.webClientCallbackURLs;
  const redirectSignOut = UserPoolClients.webClientLogoutURLs;

  return {
    Auth: {
      userPoolId: awsExports.UserPoolId,
      userPoolWebClientId: awsExports.UserPoolClientId,
      identityPoolId: awsExports.IdentityPoolId,
      region: awsExports.Region,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    aws_appsync_graphqlEndpoint: awsExports['API-Endpoint'],
    aws_appsync_region: awsExports.Region,
    aws_appsync_authenticationType: loggedIn ? 'AWS_IAM' : 'API_KEY',
    ...(!loggedIn ? {
      aws_appsync_apiKey: awsExports['API-Key'],
    } : {}),
    graphql_headers: graphqlHeader,
    oauth: {
      domain: awsExports.UserPoolDomain,
      scope: UserPoolClients.webClientScope,
      redirectSignIn,
      redirectSignOut,
      responseType: 'token',

    },
  };
};

export default setUpConfig;
