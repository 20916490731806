// @flow
/* -----------------Third parties--------------- */
import Cookies from 'js-cookie';

import {
  screenTitle,
  buttonTitle,
  prompt,
  error,
} from '../constants/auth';

import {
  signInValidationSchema,
  signUpValidationSchema,
  forgotPasswordValidationSchema,
} from '../schema/auth';

import {
  signInFormData, signUpFormData, forgotFormData, resetFormData,
} from '../constants/formdata';

export enum Keys {
  loggedIn = 'loggedIn'
}
export const getHeaderTitle = (variant: string) => {
  if (variant === 'signup') {
    return screenTitle.newAccount;
  } if (variant === 'login') {
    return screenTitle.welcome;
  }
  return screenTitle.forgotPassword;
};

export const getValidationSchema = (variant: string) => {
  if (variant === 'signup') {
    return signUpValidationSchema;
  } if (variant === 'login') {
    return signInValidationSchema;
  } if (variant === 'reset') {
    return forgotPasswordValidationSchema;
  }
  return null;
};

export const getFormData = (variant: string) => {
  if (variant === 'signup') {
    return signUpFormData;
  } if (variant === 'login') {
    return signInFormData;
  } if (variant === 'reset') {
    return resetFormData;
  }
  return forgotFormData;
};

export const getButtonTitle = (variant: string) => {
  if (variant === 'signup') {
    return screenTitle.newAccount;
  } if (variant === 'login') {
    return buttonTitle.signIn;
  } if (variant === 'reset') {
    return buttonTitle.resetPassword;
  }
  return buttonTitle.forgotPassword;
};

export const getInitialValues = (variant: string) => {
  let values = {};
  if (variant === 'signup') {
    values = { username: '', email: '', password: '' };
  } else if (variant === 'login') {
    values = { username: '', password: '' };
  } else if (variant === 'reset') {
    values = { code: '', password: '', retypePassword: '' };
  } else {
    values = { username: '' };
  }
  return values;
};

export const getFooterText = (variant: string) => {
  const property = {
    primaryText: '',
    secondaryText: '',
    primaryActionName: '',
    secondaryActionName: '',
  };
  if (variant === 'signup') {
    property.secondaryText = prompt.existingAccount;
    property.secondaryActionName = buttonTitle.signIn;
  } else if (variant === 'login') {
    property.primaryActionName = 'Forgot password?';
    property.secondaryText = prompt.newAccount;
    property.secondaryActionName = buttonTitle.signUp;
  } else if (variant === 'otp') {
    property.primaryText = 'Having trouble?';
    property.primaryActionName = buttonTitle.contactSupport;
  } else {
    property.primaryText = prompt.havingTrouble;
    property.primaryActionName = buttonTitle.contactSupport;
    property.secondaryText = prompt.rememberPassword;
    property.secondaryActionName = buttonTitle.signIn;
  }
  return property;
};

export const getErrorMessage = (err: string) => {
  switch (err) {
    case 'CodeMismatchException':
    case 'ExpiredCodeException':
      return error.invalidCode;
    case 'UsernameExistsException':
      return error.username.taken;
    case 'emailAlreadyExistsException':
    case 'UserLambdaValidationException':
    case 'userAlreadyExistsException':
      return error.emailOrUsername.taken;
    case 'NotAuthorizedException':
      return error.invalid;
    case 'UserNotFoundException':
      return error.emailOrUsername.doesNotExist;
    case 'InvalidParameterException':
      return error.emailOrUsername.doesNotExist;
    case 'InternalErrorException':
    case 'InvalidLambdaResponseException':
    case 'InvalidUserPoolConfigurationException':
    case 'ResourceNotFoundException':
    case 'TooManyRequestsException':
    case 'UnexpectedLambdaException':
      return error.contactSupport;
      // setHelperLink('support@memorang.com');
    case 'NetworkError':
      return error.network;
    case 'LimitExceededException':
      return 'Attempt limit exceeded, please try after some time.';

    default:
      return 'Something went wrong, Please try again';
  }
};

const retrieve = (k: Keys) => Cookies.get(k);
export const store = (k: Keys, value: string) => {
  Cookies.set(k, value);
};
export const isAuthenticated = () => retrieve(Keys.loggedIn) === 'true';
