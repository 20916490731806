import {
  useState,
} from 'react';
import {
  useRouter,
} from 'next/router';

/* -----------------UI--------------- */
import {
  Box,
  Button,
  Menu,
} from '@mui/material';
import {
  ChevronDown,
  Logout,
} from 'mdi-material-ui';

/* -----------------Utils--------------- */
import {
  Keys,
  store,
} from '../../utils/auth';
import setUpConfig from '../../configs/amplify';
import { Tenant } from '../../types/tenant';

/* -----------------Context--------------- */
import {
  useAuthContext,
} from '../../context/AuthContext';
import {
  signOut,
} from '../../functions/auth';
import { useTenantContext } from '../../context/TenantContext';

/* -----------------Child components--------------- */
import ListItemSettings from './ListItemSettings';
import API from '@aws-amplify/api';

const Settings = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const {
    refreshAuth,
    user,
  } = useAuthContext();
  const {
    tenant,
  } = useTenantContext();

  const logOut = async () => {
    API.configure(setUpConfig(tenant as Tenant, tenant !== 'therapyed'));
    await signOut();
    store(Keys.loggedIn, 'false');
    await refreshAuth(true);
    router.push(`/${router.pathname}`, undefined, {
      shallow: false,
    });
  };

  const email = user ? user.attributes.email : null;

  const menuItems = [
    {
      label: 'Logout',
      icon: <Logout />,
      action: logOut,
    }];

  const handleMenuItemAction = (action: () => void) => {
    action();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          textAlign="center"
          fontSize={12}
          color="secondary.main"
        >
          {email}
          <ChevronDown />
        </Box>

      </Button>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'settings-button',
        }}
      >
        {menuItems.map(({
          label,
          icon,
          action,
        }) => (
          <ListItemSettings
            key={label}
            handleAction={() => handleMenuItemAction(action)}
            label={label}
            icon={icon}
          />
        ))}
      </Menu>
    </>
  );
};
export default Settings;
