// @flow
/* -----------------Imports--------------- */
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
/* -----------------Constants--------------- */
import {
  tenantConfig,
} from '../constants/tenant';
import {
  Tenant,
} from '../types/tenant';

/* -----------------Types--------------- */
type UserProps = {
  userId: string,
  userName: string,
  email: string
}

/* ======== SETUP ========= */
// Mixpanel
const getMixpanelInstance = async () => {
  const { default: mix } = await import('mixpanel-browser');
  return mix;
};

const getDistinctId = async () => {
  const mixpanel = await getMixpanelInstance();
  const distinctId = mixpanel.get_distinct_id();
  return distinctId;
};

const initMixpanel = async (tenant : Tenant) => {
  const mixpanel = await getMixpanelInstance();
  const {
    mixPanelKey,
  } = tenantConfig[tenant];
  if (mixPanelKey) { mixpanel.init(mixPanelKey); }
};

// LogRocket
const initLogRocket = async () => {
  LogRocket.init('cmpktv/redeem-app');
  setupLogRocketReact(LogRocket);
  const mixpanel = await getMixpanelInstance();
  LogRocket.getSessionURL((sessionURL) => {
    mixpanel.track('LogRocket', { sessionURL });
  });
};

const logUsersToLogRocket = (userProps: UserProps) => {
  const {
    email,
    userId,
    userName,
  } = userProps;
  LogRocket.identify(userId, {
    name: userName,
    email,
  });
};

/* ======== AUTHENTICATION ========= */
const trackLogin = async (uid: string) => {
  const mixpanel = await getMixpanelInstance();
  // Mixpanel
  mixpanel.track('login');
  mixpanel.identify(uid);
};

type Params = {
  userId :string,
  email : string,
  username : string
}
const registerUserProperties = async (params: Params) => {
  const mixpanel = await getMixpanelInstance();
  const { userId, email, username } = params;
  mixpanel.register({
    userId,
    uuid: userId,
    email,
    username,
  });
  mixpanel.people.set({
    uuid: userId,
    email,
    username,
  });
  logUsersToLogRocket({
    userName: username,
    userId,
    email,
  });
};
const trackPageView = async (params: {
  pageType : string,
  authenticated? : boolean,
}, tenant : Tenant) => {
  // Mixpanel
  const {
    authenticated,
    pageType,
  } = params;
  await initMixpanel(tenant);
  const mixpanel = await getMixpanelInstance();
  // Mixpanel
  mixpanel.register_once({ 'First time': true }); // Tracks first-time visitors
  mixpanel.track('page viewed', { ...params }, () => {
    setTimeout(() => {
      mixpanel.register({
        'First time': false,
      });
    }, 500); // Eliminates first-time assumption
  });
  if (authenticated) {
    mixpanel.people.union('Pages visited', pageType);
    mixpanel.people.increment({
      'Pages viewed': 1,
      'Pages viewed web': 1,
    });
  }
};
export {
  getDistinctId,
  initMixpanel,
  trackLogin,
  registerUserProperties,
  trackPageView,
  initLogRocket,
  logUsersToLogRocket,
};
