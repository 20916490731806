/* -----------------Third parties--------------- */
import { createTheme } from '@mui/material/styles';

import type {
  DarkColors,
  FontSizes,
  FontWeights,
  Social,
} from './types';
import {
  branding,
  darkColors,
  error,
  social,
  fontSizes,
  fontWeights,
  fontReusables,
} from './common-theme';
import { Tenant } from '../types/tenant';

const getBrandingTheme = (darkMode : boolean, tenant? : Tenant) => {
  const mode = darkMode ? 'dark' : 'light';
  return branding[tenant || Tenant.psi][mode];
};

declare module '@mui/material/styles' {
  interface Theme {
    darkColors? : DarkColors,
    social? : Social,
    fontSizes? : FontSizes,
    fontWeights? : FontWeights
  }
  interface ThemeOptions {
    darkColors? : DarkColors,
    social? : Social,
    fontSizes? : FontSizes,
    fontWeights? : FontWeights
  }
}
const theme = (paletteType : boolean, tenant? : Tenant) => {
  const themeDefault = createTheme({ palette: { mode: paletteType ? 'dark' : 'light' } });
  const {
    primary,
    secondary,
    secondary: {
      main,
    },
  } = getBrandingTheme(paletteType, tenant);
  const themeObject = createTheme({
    darkColors,
    social,
    typography: {
      fontSize: fontSizes.body2,
      fontFamily: 'Open Sans',
    },
    palette: {
      mode: paletteType ? 'dark' : 'light',
      background: {
        paper: paletteType ? darkColors.dark
          : '#fff',
        default: paletteType ? darkColors.background
          : '#e5e5e5',
      },
      primary,
      secondary,
      error: {
        main: error.main,
      },
    },
    fontSizes,
    fontWeights,
    components: {
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontWeight: Number(fontWeights.semiBold),
          },
          h1: {
            fontWeight: Number(fontWeights.bold),
            fontSize: fontSizes.h1,
            lineHeight: 1.2,
          },
          overline: {
            ...fontReusables(paletteType).button,
            fontWeight: Number(fontWeights.bold),
            color: themeDefault.palette.text.secondary,
            lineHeight: 'inherit',
          },
          button: {
            ...fontReusables(paletteType).button,
            fontWeight: Number(fontWeights.semiBold),
          },
          subtitle2: {
            fontWeight: Number(fontWeights.semiBold),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            letterSpacing: 1.25,
            height: 48,
            borderRadius: 28,
          },
          containedSecondary: { color: '#fff' },
          outlined: {
            borderWidth: 2,
            '&:hover': {
              borderWidth: 2,
            },
          },
          outlinedSecondary: {
            borderWidth: 2,
            borderColor: main,
            '&:hover': {
              borderWidth: 2,
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: { fontSize: fontSizes.h6 },
          subheader: { fontSize: fontSizes.subtitle2 },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorSecondary: {
            color: '#fff',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            letterSpacing: 1.25,
            fontWeight: 'bold',
          },
          secondary: {
            color: '#fff',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            letterSpacing: 1.25,
            fontSize: 14,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
            fontSize: fontSizes.caption,
            fontWeight: Number(fontWeights.semiBold),
            letterSpacing: 1.25,
          },
          gutters: {
            paddingLeft: 8,
          },
        },
      },
    },
  });
  return themeObject;
};

export default theme;
