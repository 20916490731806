/* -----------------Third parties--------------- */
import {
  Auth,
} from '@aws-amplify/auth';
import type {
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth/lib-esm/types';
import { detect } from 'detect-browser';
import {
  getDistinctId,
  trackLogin,
  registerUserProperties,
} from '../analytics/tracking';

const signInUsingSocialAuth = async (provider: CognitoHostedUIIdentityProvider) => {
  await Auth.federatedSignIn({ provider });
};
const forgotPasswordVerification = async (username: string) => {
  await Auth.forgotPassword(username);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const verifyUserEmail = async (user: any) => {
  await Auth.verifyUserAttribute(user, 'email');
};

const updateUserPlatform = async (platform: string, version: string | null) => {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = {
    'custom:platform': platform,
    'custom:version': version,
  };
  const result = await Auth.updateUserAttributes(user, attributes);
  return result;
};

const signIn = async (username: string, password: string) => {
  const normalizedUserName = username.trim();
  const result = await Auth.signIn(normalizedUserName, password);
  const userId = result.attributes['custom:logicalId'];
  const browser = detect();
  if (browser) {
    const {
      name,
      version,
    } = browser;
    updateUserPlatform(name, version);
  }
  trackLogin(userId);
  registerUserProperties({
    userId,
    email: result.attributes.email,
    username: result.username,
  });
  return result;
};

const signUp = async (username: string, password: string, email: string) => {
  const distinctId = await getDistinctId();

  await Auth.signUp({
    username,
    password,
    attributes: {
      email,
    },
    validationData: [],
    clientMetadata: {
      mixpanelId: distinctId,
    },
  });
  const result = await signIn(username, password);
  return result;
};

const forgotPasswordReset = async (username: string, code: string, newPassword: string) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
};
const isAuthenticated = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const promise = new Promise((resolve) => {
      if (currentSession.getRefreshToken() && currentSession.getRefreshToken().getToken()) {
        cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
          resolve(cognitoUser);
        });
      } else {
        resolve(cognitoUser);
      }
    });
    return promise;
  } catch (e) {
    return null;
  }
};
const signOut = async () => {
  await Auth.signOut();
};

// TODO:should be removed and clientmetadata support should be added for all the apps
const signUpPSI = async (username: string, password: string, email: string) => {
  const result = await Auth.signUp({
    username,
    password,
    attributes: {
      email,
    },
    validationData: [],
  });
  return result;
};

const resendOTP = async (username: string) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    // TODO:Handle error
  }
};

const signInUsingEmail = async (email: string, appId : string, tenantId : string) => {
  const cognitoUser = await Auth.signIn({
    username: email.toLocaleLowerCase(),
    password: '',
    validationData: {
      tenantId,
      appId,
    },
  }, undefined, {
    tenantId,
    appId,
  });
  return cognitoUser;
};

const signUpUsingEmail = async (email: string, appId :string, tenantId : string) => {
  const cognitoUser = await Auth.signUp({
    username: email.toLocaleLowerCase(),
    password: 'MKpsTp9BP9jsQ38cc',
    clientMetadata: {
      tenantId,
      appId,
    },
  });
  return cognitoUser;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleCustomChallenge = async (code: string, cognitoUser: any) => {
  try {
    const res = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    if (res.signInUserSession) {
      return res;
    }
    throw new Error('Invalid code');
  } catch (error) {
    throw new Error('Invalid code');
  }
};
export {
  signInUsingSocialAuth,
  forgotPasswordVerification,
  verifyUserEmail,
  signIn,
  signUp,
  forgotPasswordReset,
  isAuthenticated,
  signOut,
  handleCustomChallenge,
  signUpPSI,
  resendOTP,
  signInUsingEmail,
  signUpUsingEmail,
};
