import { Tenant } from '../types/tenant';
import { getTenantBarcode } from '../utils/image';

const tenantConfig = {
  [Tenant.psi]: {
    appLinkAndroid:
      'https://play.google.com/store/apps/details?id=com.memorang.psi.insurance.testprep&hl=en_IN&gl=US',
    appLinkIOS:
      'https://apps.apple.com/us/app/psi-insurance-test-prep/id1565909858',
    barcode: getTenantBarcode(Tenant.psi),
    zendeskKey: process.env.ZENDESK_CLIENT_KEY,
    mixPanelKey: process.env.MIXPANEL_SECRET_KEY_PSI,
    appName: 'PSI Insurance',
    displayName: 'PSI',
    tenantIdAuth: 'dcfc373c-0870-4a88-88c3-4a706de51463',
    tenantId: 'PSI',
    passwordLessFlow: true,
    appId: '2c183c41-6dc0-4ef4-837c-0eb0f86a61b1',
    disclaimer:
      '_A **PSI Mobile Test-Prep account is required to continue**. This is different than the PSI Online account_.',
  },
  [Tenant.therapyed]: {
    appLinkAndroid:
      'https://play.google.com/store/apps/details?id=com.memorang.therapyed&hl=en_IN&gl=US',
    appLinkIOS:
      'https://apps.apple.com/us/app/therapyed-pass-the-npte/id1590041414',
    barcode: getTenantBarcode(Tenant.therapyed),
    zendeskKey: '4d6ce276-2f30-4e5a-be52-7748188d3f3d',
    mixPanelKey: process.env.MIXPANEL_SECRET_KEY_THERAPYED,
    appName: 'TherapyEd',
    displayName: 'TherapyEd',
    tenantId: 'TherapyEd',
    tenantIdAuth: 'TherapyEd',
    disclaimer:
      '_A **TherapyEd Mobile account is required to continue**. This is different than the TherapyEd Online account_.',
    passwordLessFlow: true,
    appId: 'db878dfd-9617-456b-b458-14902e06462f',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  tenantConfig,
};
