/* -----------------Types--------------- */
type CommonThemeColor = {
  light?: string,
  dark?: string,
  contrastText?: string,
  main: string
}

type Colors = {
  [type : string]: {
    primary: CommonThemeColor,
    secondary: CommonThemeColor
  },
}
const text = {
  light: 'rgba(255,255,255,0.87)',
  dark: 'rgba(0,0,0,0.87)',
};

const commonPrimaryColors = {
  contrastText: text.light,
  opacity: 'rgba(255, 255, 255, 0.87)',
  background: 'rgba(155, 213, 255, 0.2)',
};

const commonSecondaryColors = {
  contrastText: text.light,
  background: 'rgba(155, 213, 255, 0.2)',
};

const buildCommonThemeObject = (color: CommonThemeColor) => {
  const {
    light, main, dark, contrastText,
  } = color;
  return {
    light: light || main,
    main,
    dark: dark || main,
    contrastText: contrastText || text.light,
  };
};
const buildWhiteLabelTheme = (colors: Colors) => {
  const getTheme = (paletteType : string) => {
    const { [`${paletteType}`]: { primary, secondary } } = colors;
    return {
      primary: {
        ...commonPrimaryColors,
        ...buildCommonThemeObject(primary),
      },
      secondary: {
        ...commonSecondaryColors,
        ...buildCommonThemeObject(secondary),
      },
    };
  };

  return {
    light: getTheme('light'),
    dark: getTheme('dark'),
  };
};

const therapyed = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#8a1e04',
    },
    secondary: {
      main: '#16283b',
    },
  },
  dark: {
    primary: {
      main: '#F78F1E',
    },
    secondary: {
      main: '#F78F1E',
    },
  },
});

const psi = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#9E0442', // Dark fuscia
    },
    secondary: {
      main: '#002A4E', // Scholar blue
    },
  },
  dark: {
    primary: {
      main: '#3DAF8D', // Emerald,
    },
    secondary: {
      main: '#E1EEF9', // Azure white
      contrastText: text.dark,
    },
  },
});

export {
  therapyed,
  psi,
};
