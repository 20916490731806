/* -----------------Globals--------------- */
import React, {
  useState,
  useEffect,
} from 'react';

/* -----------------Third parties--------------- */
import API from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';

/* -----------------Nextjs--------------- */
import Head from 'next/head';
import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
/* -----------------UI--------------- */
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '../src/createEmotionCache';

/* -----------------Types--------------- */
import {
  Tenant,
} from '../src/types/tenant';

/* -----------------Analytics--------------- */
import {
  initMixpanel,
  initLogRocket,
} from '../src/analytics/tracking';

/* -----------------Third parties--------------- */
import {
  RelayEnvironmentProvider,
} from 'react-relay';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '../src/configs/amplify';

/* -----------------Child components--------------- */
import Header from '../src/components/chrome/Header';

/* -----------------Theme--------------- */
import theme from '../src/theme/theme';
import environment from '../src/relay/env';

/* -----------------Context--------------- */
import {
  AuthProvider,
} from '../src/context/AuthContext';
import {
  TenantProvider,
} from '../src/context/TenantContext';

const ZendeskWidget = dynamic(() => import('../src/components/widgets/Zendesk'), { ssr: false });

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
// change this to different tenant only for dev
const localTenant = Tenant.therapyed;
const isDev = process.env.NODE_ENV === 'development';

function CustomApp(
  props: AppProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emotionCache : any
  },
) {
  const initialTenantValue = isDev ? localTenant : undefined;
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(initialTenantValue);
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  React.useEffect(() => {
    if (typeof window !== undefined && !isDev) {
      const host = window.location.hostname;
      const tenantFromHost = host.split('.')[0];
      setCurrentTenant(tenantFromHost as Tenant);
    }

    initLogRocket();
  }, []);

  useEffect(() => {
    if (currentTenant) {
      initMixpanel(currentTenant);
      const amplifyConfig = setUpAmplifyConfig(currentTenant, true);
      Auth.configure(amplifyConfig);
      API.configure(amplifyConfig);
    }
  }, [currentTenant]);
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);
  function SafeHydrate({ children }: { children: React.ReactNode }) {
    return (
      <div suppressHydrationWarning>
        {typeof window === 'undefined' ? null : children}
      </div>
    );
  }
  if (!currentTenant) {
    return null;
  }
  const themeObject = theme(false, currentTenant);

  const favicon = `/tenant/${currentTenant}/favicon.ico`;
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Redeem Purchase</title>
        <link href={favicon} rel="icon" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <ThemeProvider theme={themeObject}>
        <CssBaseline />
        <RelayEnvironmentProvider environment={environment}>
          <TenantProvider tenant={currentTenant}>
            <AuthProvider>
              <Header />
              <SafeHydrate>
                <Component {...pageProps} />
              </SafeHydrate>
              <ZendeskWidget
                tenant={currentTenant}
              />
            </AuthProvider>
          </TenantProvider>
        </RelayEnvironmentProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
export default CustomApp;
