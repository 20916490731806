/* -----------------UI--------------- */
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

type Props = {
  handleAction: () => void,
  icon: React.ReactNode,
  label: string
}
const ListItemSettings = (props: Props) => {
  const {
    handleAction,
    icon,
    label,
  } = props;
  return (
    <MenuItem onClick={handleAction} style={{ minWidth: 200 }}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>
        {label}
      </ListItemText>
    </MenuItem>
  );
};

export default ListItemSettings;
