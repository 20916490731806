import {
  TenantStrings,
} from '../types/tenant';

const getTenantLogo = (tenant: TenantStrings) => `/tenant/${tenant}/logo.png`;

const getTenantIcon = (tenant: TenantStrings) => `/tenant/${tenant}/icon.png`;

const getTenantBarcode = (tenant : TenantStrings) => `/tenant/${tenant}/barcode.svg`;
export {
  // eslint-disable-next-line import/prefer-default-export
  getTenantLogo,
  getTenantIcon,
  getTenantBarcode,
};
