/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import Image from 'next/image';

/* -----------------Utils--------------- */
import {
  isAuthenticated,
} from '../../utils/auth';

/* -----------------Context--------------- */
import {
  useTenantContext,
} from '../../context/TenantContext';
import {
  useAuthContext,
} from '../../context/AuthContext';

/* -----------------UI--------------- */
import {
  AppBar,
  Container,
  Box,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/system';

/* -----------------Child components--------------- */
import Settings from '../auth/Settings';
import { getTenantLogo } from '../../utils/image';

const Header = () => {
  const theme = useTheme();
  const {
    tenant,
  } = useTenantContext();
  const {
    user,
  } = useAuthContext();

  const logo = getTenantLogo(tenant);

  return (
    <AppBar
      position="relative"
      color="default"
      elevation={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Container maxWidth="md">
        <Box py={2} width="100%">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >

            <Grid
              item
              sx={{
                position: 'relative',
                width: 150,
                height: 56,
              }}
            >

              <Image
                src={logo}
                alt={tenant}
                layout="fill"
                objectFit="contain"
                objectPosition="left"
              />
            </Grid>
            {isAuthenticated() && user ? <Grid item><Settings /></Grid> : null}
          </Grid>
        </Box>
      </Container>
    </AppBar>
  );
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default Header;
